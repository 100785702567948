const educationData = [
  {
    id: 1,
    timespan: "2006 - 2009",
    degree: "Bachelors in Computer Application",
    school: "IASE University, Rajasthan, India",
  },
  {
    id: 2,
    timespan: "2004 - 2006",
    degree: "Intermediate - Computer Science",
    school: "JNV Sultanpur, UP, India",
  },
  {
    id: 3,
    timespan: "2002 - 2004",
    degree: "High School",
    school: "JNV Raebareli, UP, India",
  },
  {
    id: 4,
    timespan: "2014 - 2014",
    degree: "Making Sense of Data Course",
    school: "Google India",
  },
  {
    id: 5,
    timespan: "2020 - 2022",
    degree: "AWS Certified Cloud Practitioner",
    school: "Amazon Web Services",
  },
  {
    id: 6,
    timespan: "2021 - 2021",
    degree: "Certfied Information Assurance",
    school: "DIAT (DRDO), India",
  },
];

const experienceData = [
  {
    id: 1,
    timespan: "2023 - ....",
    position: "VP Technology",
    company: "The Lending Tree, Dubai",
  },
  {
    id: 2,
    timespan: "2018 - 2023",
    position: "Head Of IT & Security",
    company: "DSSC Tech LTD. Dubai",
  },
  {
    id: 3,
    timespan: "2017 - 2018",
    position: "Sr. DevOps Engineer",
    company: "Econet Vision Limited, Dubai",
  },
  {
    id: 4,
    timespan: "2014 - 2017",
    position: "Sr. Data Engineer / DevOps",
    company: "Ogilvy & Mather - neo@Ogilvy, Gurugram, India",
  },
  {
    id: 5,
    timespan: "2012 - 2014",
    position: "Web Manager",
    company: "Smiles Group, Gurugram, India",
  },
  {
    id: 6,
    timespan: "2012 - 2012",
    position: "Sr. System Admin / DevOps",
    company: "Tech Freaks Pvt. LTD, Lucknow, India",
  },
  {
    id: 7,
    timespan: "2011 - 2012",
    position: "System Admin / DevOps",
    company: "Elite Business Media Pvt. Ltd., Lucknow, India",
  },
];

export {
  educationData,
  experienceData,
};
