import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import TickerTextSlider from "../slider/TickerTextSlider";

const BannerSection = () => {
  const isThemeDark = useAppSelector((state) => state.themeToggle.isThemeDark);

  const arrowIcon = isThemeDark
    ? "/assets/img/banner-link-arrow-light.png"
    : "/assets/img/banner-link-arrow.png";
  const shapes = isThemeDark
    ? "/assets/img/banner-shape-stroke-light.png"
    : "/assets/img/banner-shape-stroke.png";

  // Replace with the actual path to your resume
  const resumePath = '/assets/surya_resume.pdf';

  return (
    <section className="nk-banner">
      <div className="container">
        <h1 className="nk-banner__title text-animate">
          🚀 I’m Surya, helping advance <br />
          <span className="colored">Technology.</span>
        </h1>

        <div className="row g-3">
          <div className="col-lg-3 col-md-4 col-6">
            <div className="nk-banner__links text-end">
              <Link to="/about" className="nk-banner__link">
                <span className="main-icon">
                  <img src="/assets/img/banner-link-1.png" alt="About Me icon" loading="lazy" />
                </span>
                <span className="link-txt">About Me</span>
                <span className="arrow-icon">
                  <img src={arrowIcon} alt="Arrow icon" loading="lazy" />
                </span>
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-md-4 order-2 order-md-1">
            <div className="nk-banner__img">
              <img src="/assets/img/banner-img.webp" alt="Banner Image" loading="eager" width="600" height="400" />

              <div className="vectors">
                <img src="/assets/img/banner-vector-1.png" alt="Vector 1" loading="lazy" />
                <img
                  src={
                    isThemeDark
                      ? "/assets/img/banner-vector-3.png"
                      : "/assets/img/banner-vector-2.png"
                  }
                  alt="Vector 2"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-6 order-1 order-md-2">
            <div className="nk-banner__links">
              <a href={resumePath} download="Surya_Resume.pdf" className="nk-banner__link">
                <span className="main-icon">
                  <img src="/assets/img/banner-link-2.png" alt="Resume icon" />
                </span>
                <span className="link-txt">Resume</span>
                <span className="arrow-icon">
                  <img src={arrowIcon} alt="Arrow icon" loading="lazy" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="nk-banner__shapes">
        <img src={shapes} alt="Stroke shape" loading="lazy" />
        <img src={shapes} alt="Stroke shape" loading="lazy" />
      </div>
      <TickerTextSlider />
    </section>
  );
};

export default BannerSection;