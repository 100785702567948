import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";

type Inputs = {
  name: string;
  email: string;
  message: string;
};

const ContactForm = () => {
  const { register, handleSubmit, reset } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_API_URL}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json(); // Get response data for logging

      if (response.ok) {
        toast.success("Message submitted successfully!");
        reset(); // Reset the form to default values
      } else {
        console.error("Response Error:", responseData); // Log response error
        toast.error("Failed to send message. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <form
      action="#"
      className="nk-contact-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input
        type="text"
        id="nk-contact-form-name"
        placeholder="Your Full Name"
        {...register("name")}
        required
      />
      <input
        type="email"
        id="nk-contact-form-email"
        placeholder="Email Address"
        {...register("email")}
        required
      />
      <textarea
        id="nk-contact-form-message"
        rows={10}
        placeholder="Your Message"
        {...register("message")}
        required
      ></textarea>
      <button type="submit" className="nk-def-btn">
        Send Message
      </button>
    </form>
  );
};

export default ContactForm;
